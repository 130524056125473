import React from 'react';
import { array } from 'prop-types';

const AboveTheFold = ({ posts }) => (
  <>
    <div className="above-the-fold__mobile">
      <div className="first-post">{posts[0]}</div>
    </div>
    <div className="above-the-fold__tablet">
      <div className="first-column">
        <div className="first-post">{posts[0]}</div>
      </div>
      <div className="second-column">
        <div className="second-post">{posts[1]}</div>
        <div className="third-post">{posts[2]}</div>
      </div>
    </div>
    <div className="above-the-fold__laptop">
      <div className="first-post">{posts[0]}</div>
      <div className="second-column">
        <div className="second-post">{posts[1]}</div>
        <div className="third-post">{posts[2]}</div>
      </div>
      <div className="third-column">
        <div className="fourth-post">{posts[3]}</div>
        <div className="fifth-post">{posts[4]}</div>
      </div>
    </div>
  </>
);

AboveTheFold.propTypes = {
  posts: array.isRequired,
};

export default AboveTheFold;
