import React from 'react';
import { array } from 'prop-types';

const BelowTheFold = ({ posts }) => (
  <>
    <div className="below-the-fold__mobile">{posts.slice(1)}</div>
    <div className="below-the-fold__tablet">{posts.slice(2)}</div>
    <div className="below-the-fold__laptop">{posts.slice(6)}</div>
  </>
);

BelowTheFold.propTypes = {
  posts: array.isRequired,
};

export default BelowTheFold;
