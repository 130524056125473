import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faHome,
  faHeartbeat,
  faGlobeAfrica,
  faGrinSquintTears,
  faSlash,
} from '@fortawesome/free-solid-svg-icons';

import AboveTheFold from '../components/above-the-fold';
import BelowTheFold from '../components/below-the-fold';

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faHome,
  faHeartbeat,
  faGlobeAfrica,
  faGrinSquintTears,
  faSlash,
);

const BlogIndex = () => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { published: { eq: true } } }
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date
              title
              image
            }
          }
        }
      }
    `}
    render={({ site, allMarkdownRemark }) => {
      const siteTitle = site.siteMetadata.title;
      const posts = allMarkdownRemark.nodes;
      const postsArray = posts.map(({ frontmatter, fields, excerpt }, idx) => {
        const title = frontmatter.title || node.fields.slug;
        const image = frontmatter.image;
        return (
          <div className="index-template" key={idx}>
            <Link to={fields.slug}>
              {image && <img src={image} />}
              <div className="index-template__title">
                <h3>{title}</h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: excerpt }} />
            </Link>
          </div>
        );
      });

      return (
        <div className="index">
          <Helmet title={siteTitle} />
          <AboveTheFold posts={postsArray.slice(0, 6)} />
          <BelowTheFold posts={postsArray} />
        </div>
      );
    }}
  />
);

export default BlogIndex;
